<template>
    <b-row>
        <b-col cols="12">
            <div
                class="d-sm-flex justify-content-between align-items-center mb-4"
            >
                <feather
                    type="arrow-left-circle"
                    v-b-popover.hover.top="'Presione esc'"
                    role="button"
                    class="text-secondary"
                    size="2rem"
                    @click="back"
                ></feather>
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        size="md"
                        class="mr-4"
                        v-b-popover.hover.top="'Presione esc'"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <b-col
            cols="12"
            lg="12"
            class="d-flex align-items-stretch"
            style="min-height: 70vh"
        >
            <b-card class="mb-4 w-100">
                <div>
                    <b-form class="p-5">
                        <b-form v-hotkey="keymap">
                            <b-row class="mb-5">
                                <b-col cols="12" sm="6">
                                    <label>Número documento</label>
                                </b-col>
                                <b-col cols="12" sm="3">
                                    <!-- <label for="prefijo_devolucion"
                                                >Prefijo</label
                                            > -->
                                    <b-form-input
                                        id="prefijo"
                                        disabled
                                        v-model="
                                            factura_venta.consecutivo.prefijo
                                        "
                                        :state="validateState('consecutivo')"
                                    />
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.factura_venta.consecutivo.$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-col>
                                <b-col cols="12" sm="3">
                                    <!-- <label for="numero_devolucion"
                                                >Número</label
                                            > -->
                                    <input
                                        id="numero_factura"
                                        type="number"
                                        class="form-control"
                                        disabled
                                        v-model="factura_venta.numero"
                                    />
                                </b-col>
                            </b-row>

                            <b-row class="mt-4">
                                <b-col cols="12">
                                    <label for="proveedor_id">Proveedor</label>
                                    <jautocomplete
                                        id="proveedor_id"
                                        ref="proveedor_id"
                                        :items="proveedores"
                                        item-text="nombre1"
                                        item-value="id"
                                        load-async
                                        open-on-focus
                                        @text="filterCliente"
                                        @change="assignPorveedor"
                                        :state="validateState('proveedor_id')"
                                        :disabled="read_only"
                                        v-model="
                                            $v.factura_venta.proveedor_id.$model
                                        "
                                        show-create-button
                                        :create-method="
                                            () => {
                                                $bvModal.show(
                                                    'create-tercero-modal'
                                                )
                                            }
                                        "
                                    >
                                        <template v-slot:option="{ item }">
                                            <option
                                                v-if="item.enabled"
                                                class="text-black"
                                            >
                                                {{ getLabelCliente(item) }}
                                            </option>
                                            <option
                                                v-else
                                                :disabled="!item.enabled"
                                                class="text-secondary"
                                            >
                                                {{ getLabelCliente(item) }}
                                            </option>
                                        </template>
                                    </jautocomplete>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="
                                            $v.factura_venta.proveedor_id.$error
                                        "
                                    >
                                        Campo requerido
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row class="mt-4">
                                <b-col cols="12" md="4">
                                    <label for="fecha">Fecha</label>
                                    <b-input-group>
                                        <b-form-input
                                            id="fecha"
                                            v-model="
                                                $v.factura_venta.fecha.$model
                                            "
                                            type="text"
                                            placeholder="AAAA-MM-DD"
                                            :state="validateState('fecha')"
                                            disabled
                                            autocomplete="null"
                                        ></b-form-input>
                                        <b-input-group-append>
                                            <b-form-datepicker
                                                v-model="
                                                    $v.factura_venta.fecha
                                                        .$model
                                                "
                                                button-only
                                                right
                                                locale="en-US"
                                                :state="validateState('fecha')"
                                                aria-controls="fecha"
                                                disabled
                                            ></b-form-datepicker>
                                        </b-input-group-append>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.factura_venta.fecha.formatDate
                                        "
                                    >
                                        Formato de fecha inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.factura_venta.fecha.compareDates
                                        "
                                    >
                                        La fecha no puede ser mayor al
                                        vencimiento
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <label for="plazo">Plazo (días)</label>
                                    <b-form-input
                                        id="plazo"
                                        v-model="$v.plazo_vencimiento.$model"
                                        :disabled="
                                            !$v.factura_venta.proveedor_id
                                                .$model || read_only
                                        "
                                        @input="assignVencimiento"
                                        type="number"
                                        :state="
                                            validateStateSingle(
                                                'plazo_vencimiento'
                                            )
                                        "
                                        autocomplete="null"
                                    ></b-form-input>
                                    <b-form-invalid-feedback
                                        v-if="
                                            !$v.plazo_vencimiento
                                                .noNegativeNumbers
                                        "
                                    >
                                        El plazo no puede ser negativo
                                    </b-form-invalid-feedback>
                                </b-col>
                                <b-col cols="12" md="4">
                                    <label for="vencimiento">Vencimiento</label>
                                    <b-input-group>
                                        <b-form-input
                                            id="vencimiento"
                                            v-model="
                                                $v.factura_venta.vencimiento
                                                    .$model
                                            "
                                            type="text"
                                            placeholder="AAAA-MM-DD"
                                            disabled
                                            autocomplete="null"
                                        ></b-form-input>
                                    </b-input-group>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.factura_venta.vencimiento
                                                .formatDate
                                        "
                                    >
                                        Formato de fecha inválido
                                    </b-form-invalid-feedback>
                                    <b-form-invalid-feedback
                                        :state="
                                            $v.factura_venta.vencimiento
                                                .compareDates
                                        "
                                    >
                                        El vencimiento de la factura no puede
                                        ser mayor a la fecha
                                    </b-form-invalid-feedback>
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col cols="12">
                                    <label for="nota">Nota</label>
                                    <b-input-group>
                                        <b-form-textarea
                                            id="nota"
                                            size="sm"
                                            :disabled="read_only"
                                            v-model="
                                                $v.factura_venta.nota.$model
                                            "
                                            rows="1"
                                            placeholder="Alguna anotación?..."
                                        ></b-form-textarea>
                                    </b-input-group>

                                    <div
                                        class="length-field mt-1"
                                        v-if="!$v.factura_venta.nota.$maxLength"
                                    >
                                        ({{
                                            $v.factura_venta.nota.$model.length
                                        }}
                                        /
                                        {{
                                            $v.factura_venta.nota.$params
                                                .maxLength.max
                                        }})
                                    </div>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="!$v.factura_venta.nota.maxLength"
                                    >
                                        Este campo debe tener máximo
                                        {{
                                            $v.factura_venta.nota.$params
                                                .maxLength.max
                                        }}
                                        caracteres
                                    </div>
                                </b-col>
                            </b-row>
                            <b-row class="mt-5">
                                <b-col cols="12">
                                    <div
                                        class="d-block d-sm-flex justify-content-between mb-5"
                                    >
                                        <label class="mb-4 font-weight-bold"
                                            >Productos</label
                                        >
                                        <div>
                                            <b-button
                                                variant="primary"
                                                size="md"
                                                class="d-flex align-items-center mt-4 mt-sm-0"
                                                @click="
                                                    $bvModal.show(
                                                        'modal-detalle-factura'
                                                    )
                                                "
                                            >
                                                <feather
                                                    type="eye"
                                                    size="1rem"
                                                    class="mr-2 text-blue-active"
                                                ></feather>
                                                Detalles de retenciones
                                            </b-button>
                                            <detalleFacturaVue
                                                :details="detalle_factura"
                                            />
                                        </div>
                                    </div>
                                    <b-row class="my-4">
                                        <b-col cols="12">
                                            <b-button
                                                variant="primary"
                                                size="md"
                                                class="d-flex align-items-center mt-4 mt-sm-0"
                                                @click="
                                                    $bvModal.show('modal-items')
                                                "
                                                ref="itemsButton"
                                                v-b-popover.hover.top="
                                                    'ctrl+b ó cmd+b'
                                                "
                                                :disabled="read_only"
                                            >
                                                <feather
                                                    type="search"
                                                    size="1rem"
                                                    class="mr-2 text-blue-active"
                                                ></feather>
                                                Más items
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                    <b-table
                                        small
                                        hover
                                        responsive
                                        foot-clone
                                        striped
                                        :items="factura_venta.productos"
                                        :fields="titulosProductos"
                                    >
                                        <template #cell(referencia)="data">
                                            <jautocomplete
                                                v-if="!data.item.item_id"
                                                :id="`referencia-${data.item.item_id}`"
                                                :items="productos"
                                                item-text="referencia"
                                                item-value="id"
                                                return-object
                                                load-async
                                                open-on-focus
                                                :disabled="read_only"
                                                @text="filterProductos"
                                                @change="insertProducto"
                                                show-create-button
                                                :create-method="
                                                    () => {
                                                        $bvModal.show(
                                                            'create-item-modal'
                                                        )
                                                    }
                                                "
                                            />
                                            <span v-else>{{ data.value }}</span>
                                        </template>
                                        <template #cell(descripcion)="data">
                                            <jautocomplete
                                                v-if="!data.item.item_id"
                                                :id="`producto-${data.item.item_id}`"
                                                :items="productos"
                                                item-text="descripcion"
                                                item-value="id"
                                                return-object
                                                load-async
                                                open-on-focus
                                                :disabled="read_only"
                                                @text="filterProductos"
                                                @change="insertProducto"
                                                show-create-button
                                                :create-method="
                                                    () => {
                                                        $bvModal.show(
                                                            'create-item-modal'
                                                        )
                                                    }
                                                "
                                            >
                                                <template
                                                    v-slot:option="{ item }"
                                                >
                                                    <span>
                                                        {{
                                                            getLabelItem(item)
                                                        }}</span
                                                    >
                                                </template>
                                            </jautocomplete>
                                            <!-- <span v-else>{{ data.value }}</span> -->
                                            <b-form-input
                                                v-if="
                                                    data.item.item_id !==
                                                        null &&
                                                    data.item.item_id !==
                                                        undefined
                                                "
                                                type="text"
                                                v-model="
                                                    $v.factura_venta.productos
                                                        .$each[data.index]
                                                        .descripcion.$model
                                                "
                                                :id="`producto-${data.item.item_id}`"
                                                :disabled="
                                                    read_only ||
                                                    !data.item.item_id
                                                "
                                                :state="
                                                    validateStateProducts(
                                                        data.index,
                                                        'descripcion'
                                                    )
                                                "
                                            ></b-form-input>
                                            <!-- <span v-else>{{ data.value }}</span> -->
                                            <b-form-invalid-feedback
                                                v-if="
                                                    !$v.factura_venta.productos
                                                        .$each[data.index]
                                                        .descripcion.maxLength
                                                "
                                            >
                                                Este campo debe tener máximo
                                                {{
                                                    $v.factura_venta.productos
                                                        .$each[data.index]
                                                        .descripcion.$params
                                                        .maxLength.max
                                                }}
                                                caracteres
                                            </b-form-invalid-feedback>
                                            <b-form-invalid-feedback
                                                v-if="
                                                    !$v.factura_venta.productos
                                                        .$each[data.index]
                                                        .descripcion.required
                                                "
                                            >
                                                Campo requerido
                                            </b-form-invalid-feedback>
                                        </template>
                                        <!-- <template
                                            #cell(precio_base_venta)="data"
                                        >
                                            <currency-input
                                                :id="`precio-base-venta-producto-${data.item.item_id}`"
                                                :disabled="
                                                    read_only ||
                                                    !data.item.item_id
                                                "
                                                v-model.number="
                                                    $v.factura_venta.productos
                                                        .$each[data.index]
                                                        .precio_base_venta
                                                        .$model
                                                "
                                                @focus="onFocused"
                                                :state="
                                                    validateStateProducts(
                                                        data.index,
                                                        'precio_base_venta'
                                                    )
                                                "
                                            >
                                            </currency-input>
                                            <div
                                                class="invalid-field mt-1"
                                                v-if="
                                                    !$v.factura_venta.productos
                                                        .$each[data.index]
                                                        .precio_base_venta
                                                        .required &&
                                                    $v.factura_venta.productos
                                                        .$each[data.index]
                                                        .precio_base_venta
                                                        .$dirty
                                                "
                                            >
                                                Campo requerido
                                            </div>
                                        </template> -->
                                        <!-- <template #cell(valor)="data">
                                            {{
                                                currencyFormat(calcValor(data))
                                            }}
                                        </template> -->
                                        <template #cell(valor)="data">
                                            <currency-input
                                                :id="`valor-${data.item.item_id}`"
                                                :disabled="
                                                    read_only ||
                                                    !data.item.item_id
                                                "
                                                v-model.number="
                                                    $v.factura_venta.productos
                                                        .$each[data.index].valor
                                                        .$model
                                                "
                                                @focus="onFocused"
                                                @input="
                                                    () => {
                                                        calcTotalIva(data)
                                                        calcDescuento(data)
                                                    }
                                                "
                                                :state="
                                                    validateStateProducts(
                                                        data.index,
                                                        'valor'
                                                    )
                                                "
                                            >
                                            </currency-input>
                                            <!-- <div
                                                class="invalid-field mt-1"
                                                v-if="
                                                    !$v.factura_venta.productos
                                                        .$each[data.index].valor
                                                        .required &&
                                                    $v.factura_venta.productos
                                                        .$each[data.index].valor
                                                        .$dirty
                                                "
                                            >
                                                Campo requerido
                                            </div> -->
                                        </template>
                                        <template #cell(cantidad)="data">
                                            <!-- @keyup.enter="
                                                    goToNextField(
                                                        `precio-base-venta-producto-${data.item.item_id}`
                                                    )
                                                "
                                                @keyup.tab="
                                                    goToNextField(
                                                        `precio-base-venta-producto-${data.item.item_id}`
                                                    )
                                                " -->
                                            <b-form-input
                                                :id="`cantidad-producto-${data.item.item_id}`"
                                                :disabled="
                                                    read_only ||
                                                    !data.item.item_id
                                                "
                                                @focus="onFocused"
                                                v-model="
                                                    $v.factura_venta.productos
                                                        .$each[data.index]
                                                        .cantidad.$model
                                                "
                                                type="number"
                                                :state="
                                                    validateStateProducts(
                                                        data.index,
                                                        'cantidad'
                                                    )
                                                "
                                                autocomplete="null"
                                            ></b-form-input>
                                            <b-form-invalid-feedback
                                                v-if="
                                                    !$v.factura_venta.productos
                                                        .$each[data.index]
                                                        .cantidad.required
                                                "
                                            >
                                                Campo requerido
                                            </b-form-invalid-feedback>
                                            <b-form-invalid-feedback
                                                v-if="
                                                    !$v.factura_venta.productos
                                                        .$each[data.index]
                                                        .cantidad.maxLength
                                                "
                                            >
                                                Máximo
                                                {{
                                                    $v.factura_venta.productos
                                                        .$each[data.index]
                                                        .cantidad.$params
                                                        .maxLength.max
                                                }}
                                                caracteres
                                            </b-form-invalid-feedback>
                                        </template>

                                        <template
                                            #cell(porcentaje_descuento)="data"
                                        >
                                            <b-form-input
                                                :id="`porcentaje-descuento-producto-${data.item.item_id}`"
                                                type="number"
                                                placeholder="Ej: 19"
                                                :disabled="read_only ||
                                                    !data.item.item_id ||
                                                    !permisos.includes('remision_proveedor-modificar-descuento')
                                                "
                                                v-model="
                                                    $v.factura_venta.productos
                                                        .$each[data.index]
                                                        .porcentaje_descuento
                                                        .$model
                                                "
                                                @focus="onFocused"
                                                @keydown.enter="
                                                    goToNextField('productos')
                                                "
                                                @keydown.tab="
                                                    goToNextField('productos')
                                                "
                                                @input="
                                                    () => {
                                                        calcDescuento(data)
                                                    }
                                                "
                                                :state="
                                                    validateStateProducts(
                                                        data.index,
                                                        'porcentaje_descuento'
                                                    )
                                                "
                                            >
                                            </b-form-input>
                                            <b-form-invalid-feedback
                                                v-if="
                                                    !$v.factura_venta.productos
                                                        .$each[data.index]
                                                        .porcentaje_descuento
                                                        .maxLength
                                                "
                                            >
                                                Máximo
                                                {{
                                                    $v.factura_venta.productos
                                                        .$each[data.index]
                                                        .porcentaje_descuento
                                                        .$params.maxLength.max
                                                }}
                                                caracteres
                                            </b-form-invalid-feedback>
                                        </template>

                                        <template #cell(total_iva)="data">
                                            {{
                                                data.item.tarifa_iva.porcentaje
                                            }}%
                                        </template>

                                        <template
                                            #cell(total_impoconsumo)="data"
                                        >
                                            {{
                                                currencyFormat(
                                                    calcTotalImpoconsumo(data).toFixed(2)
                                                )
                                            }}
                                        </template>

                                        <template #cell(total)="data">
                                            {{
                                                currencyFormat(calcTotal(data).toFixed(2))
                                            }}
                                        </template>

                                        <template #cell(acciones)="row">
                                            <feather
                                                v-if="
                                                    read_only ||
                                                    !row.item.item_id
                                                "
                                                v-b-popover.hover.top="
                                                    'Eliminar producto'
                                                "
                                                type="trash-2"
                                                class="text-secondary"
                                                size="1.3rem"
                                            />

                                            <feather
                                                v-else
                                                v-b-popover.hover.top="
                                                    'Eliminar producto'
                                                "
                                                type="trash-2"
                                                class="text-error"
                                                size="1.3rem"
                                                role="button"
                                                @click="confirmRemoveItem(row)"
                                            />

                                            <feather
                                                v-if="!row.item.item_id"
                                                :type="
                                                    row.detailsShowing
                                                        ? 'arrow-up-circle'
                                                        : 'arrow-down-circle'
                                                "
                                                v-b-popover.hover.top="
                                                    row.detailsShowing
                                                        ? 'Ocultar detalles'
                                                        : 'Ver detalles'
                                                "
                                                size="1.3rem"
                                                role="button"
                                                class="text-secondary ml-2"
                                            />

                                            <feather
                                                v-else
                                                :type="
                                                    row.detailsShowing
                                                        ? 'arrow-up-circle'
                                                        : 'arrow-down-circle'
                                                "
                                                v-b-popover.hover.top="
                                                    row.detailsShowing
                                                        ? 'Ocultar detalles'
                                                        : 'Ver detalles'
                                                "
                                                @click="
                                                    ($evt) => {
                                                        row.toggleDetails($evt)
                                                        setBodegaAndLote(
                                                            row.item
                                                        )
                                                    }
                                                "
                                                size="1.3rem"
                                                role="button"
                                                class="text-secondary ml-2"
                                            />
                                        </template>

                                        <template #foot()="data">
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'referencia'
                                                "
                                            >
                                                <b>Subtotal</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.subtotal_neto.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'descripcion'
                                                "
                                            >
                                                <b>Descuento</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.descuento.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'total_iva'
                                                "
                                            >
                                                <b>Iva</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.total_iva.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'cantidad'
                                                "
                                            >
                                                <b>Impoconsumo</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            totalesFacturas.total_ipoconsumo.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'porcentaje_descuento'
                                                "
                                            >
                                                <b>Retefuente</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            total_retefuente.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key === 'valor'
                                                "
                                            >
                                                <b>Reteiva</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            total_reteiva.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                            <div
                                                v-if="
                                                    data.field.key ===
                                                    'acciones'
                                                "
                                            >
                                                <b>Total</b>
                                                <div>
                                                    {{
                                                        currencyFormat(
                                                            granTotal.toFixed(2)
                                                        )
                                                    }}
                                                </div>
                                            </div>
                                        </template>

                                        <template #row-details="row">
                                            <b-card>
                                                <!-- {{ row.item }} -->
                                                <b-row class="pa-2">
                                                    <b-col cols="2">
                                                        <b>Descuento</b>
                                                        <div>
                                                            {{
                                                                currencyFormat(
                                                                    row.item
                                                                        .descuento.toFixed(2)
                                                                )
                                                            }}
                                                        </div>
                                                    </b-col>
                                                    <b-col cols="2">
                                                        <label for="bodega_id"
                                                            >Bodega</label
                                                        >
                                                        <jautocomplete
                                                            id="bodega_id"
                                                            :items="bodegas"
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="
                                                                row.item
                                                                    .bodega_id
                                                            "
                                                            :disabled="
                                                                read_only ||
                                                                !row.item.item_id
                                                                || !permisos.includes('remision_proveedor-modificar-bodega')
                                                            "
                                                        />
                                                        <!-- :disabled="
                                                                bodegas.length ===
                                                                    0 ||
                                                                read_only
                                                            " -->
                                                        <!-- :state="validateState('bodega_id')" -->
                                                        <!-- <div
                                                            class="invalid-field mt-1"
                                                            v-if="
                                                                $v.factura_venta
                                                                    .bodega_id
                                                                    .$error
                                                            "
                                                        >
                                                            Campo requerido
                                                        </div> -->
                                                    </b-col>
                                                    <b-col cols="2">
                                                        <label
                                                            for="lote_inventario_id"
                                                            >Lote de
                                                            inventario</label
                                                        >
                                                        <jautocomplete
                                                            id="lote_inventario_id"
                                                            :items="
                                                                lotes_inventario
                                                            "
                                                            item-text="nombre"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="
                                                                row.item
                                                                    .lote_inventario_id
                                                            "
                                                            :disabled="
                                                                read_only ||
                                                                !row.item.item_id
                                                                || !permisos.includes('remision_proveedor-modificar-lote')
                                                            "
                                                        />
                                                    </b-col>

                                                    <b-col cols="2">
                                                        <label
                                                            for="tercero_id"
                                                            >Tercero</label
                                                        >
                                                        <jautocomplete
                                                            id="tercero_id"
                                                            :items="tercerosCopy"
                                                            item-value="id"
                                                            item-text="n_documento"
                                                            :custom-filter="filterTerceros"
                                                            open-on-focus
                                                            :custom-text-field="textFieldTercero"
                                                            v-model="row.item.tercero_id"
                                                            show-create-button
                                                            :disabled="read_only ||
                                                                !row.item.item_id ||
                                                                !permisos.includes('remision_proveedor-modificar-tercero')
                                                            "
                                                        >
                                                            <template v-slot:option="{ item }">
                                                                <div
                                                                    class="p-1 option"
                                                                    role="button"
                                                                >
                                                                    {{
                                                                        item.tipo_documento_id ===
                                                                        '31'
                                                                        ? item.razon_social
                                                                        : `${item.nombre1} ${item.apellido1}`
                                                                    }}
                                                                </div>
                                                            </template>
                                                        </jautocomplete>
                                                    </b-col>

                                                    <b-col cols="2">
                                                        <label
                                                            for="concepto_contable_id"
                                                            >Concepto contable</label
                                                        >
                                                        <jautocomplete
                                                            id="concepto_contable_id"
                                                            :items="conceptos_contables
                                                                    "
                                                            item-text="descripcion"
                                                            item-value="id"
                                                            open-on-focus
                                                            v-model="row.item
                                                                .concepto_contable_id
                                                                "
                                                            @change="
                                                                getExistenciaProducto(
                                                                    row
                                                                )
                                                                "
                                                            :disabled="read_only ||
                                                                !row.item.item_id ||
                                                                !permisos.includes('remision_proveedor-modificar-concepto-contable')
                                                            "
                                                        />
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </template>
                                    </b-table>
                                    <div
                                        class="invalid-field mt-1"
                                        v-if="productsLengthError"
                                    >
                                        La lista de productos debe tener al
                                        menos
                                        {{
                                            $v.factura_venta.productos.$params
                                                .minLength.min
                                        }}
                                        producto
                                    </div>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-form>
                    <prefijoModal
                        :consecutivos="consecutivos"
                        @selectedConsecutivo="setConsecutivo"
                    />
                    <!-- getNumeroFactura(consecutivo.id) -->
                </div>
            </b-card>
        </b-col>
        <b-col cols="12">
            <div class="float-right">
                <div class="d-flex flex-row">
                    <b-button
                        variant="secondary"
                        :disabled="isLoading"
                        size="md"
                        class="mr-4"
                        v-b-popover.hover.top="'Presione esc'"
                        @click="back"
                    >
                        Cancelar
                    </b-button>

                    <b-button
                        variant="primary"
                        :disabled="isLoading || read_only"
                        size="md"
                        class="d-flex align-items-center"
                        v-b-popover.hover.top="
                            'Presione ctrl+shift+s o cmd+shift+s'
                        "
                        @click="save"
                    >
                        <feather
                            type="save"
                            size="1rem"
                            class="mr-2 text-blue-active"
                        />
                        <span
                            v-if="isLoading"
                            class="d-sm-flex align-items-center justify-content-center"
                        >
                            <b-spinner small></b-spinner>
                            <span class="ml-2">Guardando...</span>
                        </span>
                        <span v-else>Guardar</span>
                    </b-button>
                </div>
            </div>
        </b-col>
        <!-- <crearVendedoresModalVue @newVendedor="setNewVendedor" /> -->
        <crearTerceroModal @newTercero="setNewProveedor" />
        <crearItemVue @newItem="setNewItem" />
        <modalItem @selectedItem="insertProducto" />
    </b-row>
</template>
<script>
import services from '@/boot/axios'
import { validationMixin } from 'vuelidate'
import {
    required,
    maxLength,
    minLength
    // requiredIf
} from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'
import { compareDates, dateNow /* daysLefts */ } from '@/utils/date'
import jautocomplete from '@/components/shared/autocomplete/jautocomplete'
import {
    addListenerCommands,
    removeListenerCommands,
    goToNextField,
    onFocused,
    currencyFormat,
    compareObjects
} from '@/utils/others'
import { filterTerceros } from '@/utils/filters'
import { calcRetefuente } from '@/utils/facturaVenta'
import prefijoModal from '@/components/shared/prefijo-modal/prefijoModal'
import detalleFacturaVue from '@/components/shared/detalle-factura/detalleFactura.vue'
import currencyInput from '@/components/shared/currency-input/currency-input'
import print from 'print-js'
// import crearVendedoresModalVue from '@/views/administrativo/configuracion/vendedores/crearVendedoresModal.vue'
import crearTerceroModal from '../../../general/generales/terceros/crearTerceroModal'
import crearItemVue from '../../inventario/items/crearItemModal.vue'
import modalItem from '@/components/shared/modal-items/modal-items.vue'

const { API } = services

const defaultFacturaVenta = () =>
    JSON.parse(
        JSON.stringify({
            nota: '',
            numero: '',
            fecha: dateNow(),
            vencimiento: dateNow(),
            proveedor_id: null,
            gran_total: 0,
            subtotal: 0,
            total_reteiva: 0,
            total_descuento: 0,
            total_ica: 0,
            total_ipoconsumo: 0,
            total_iva: 0,
            total_retefuente: 0,
            // consecutivo_proveedor: '',
            consecutivo: { prefijo: '' },
            tipo_documento_id: 2,
            estado_documento_id: null,
            subtotal_neto: 0,
            tercero_id: null,
            usuario_id: null,
            // vendedor_id: null,
            sede_id: null,
            empresa_id: null,
            configuracion_impuestos: {},
            // formas_pago: [],
            productos: [],
            cambio: 0
        })
    )

const defaultProducto = () =>
    JSON.parse(
        JSON.stringify({
            descripcion: '',
            cantidad: 1,
            descuento: 0,
            ipoconsumo: 0,
            porcentaje_descuento: 0,
            precio: 0,
            retefuente: 0,
            // precio_base_venta: 0,
            valor: 0,
            valor_ica: 0,
            valor_iva: 0,
            subtotal: 0,
            proveedor_id: null,
            subtotal_neto: 0,
            total: 0,
            bodega_id: null,
            lote_inventario_id: null,
            concepto_contable_id: null,
            tercero_id: null,
            item_id: null,
            _showDetails: false
        })
    )

export default {
    name: 'CrearRemisionProveedor',
    mixins: [validationMixin],
    components: {
        jautocomplete,
        prefijoModal,
        detalleFacturaVue,
        currencyInput,
        // crearVendedoresModalVue,
        crearTerceroModal,
        crearItemVue,
        modalItem
    },
    data: () => ({
        conceptos_contables: [],
        terceros: [],
        bodegas: [],
        lotes_inventario: [],
        tercerosCopy: [],
        productos: [],
        // vendedores: [],
        plazo_vencimiento: 0,
        productsLengthError: null,
        detalle_factura: {},
        retencionBaseData: {
            tercero: {
                declarante: false,
                gran_contribuyente: false,
                agente_retencion_fuente: false,
                regimen_simple_tributacion: false
            },
            empresa: {
                agente_retencion_fuente: false,
                autoretenedor_fuente: false
            }
        },
        titulosProductos: [
            {
                key: 'referencia',
                label: 'Referencia',
                class: 'text-center',
                thStyle: { maxWidth: '10rem', textAlign: 'center' }
            },
            {
                key: 'descripcion',
                label: 'Producto',
                class: 'text-center',
                thStyle: { minWidth: '18rem', textAlign: 'center' }
            },
            {
                key: 'cantidad',
                label: 'Cantidad',
                class: 'text-center',
                thStyle: { textAlign: 'center', maxWidth: '7rem' }
            },
            {
                key: 'valor',
                label: 'Valor',
                class: 'text-center',
                thStyle: { minWidth: '12rem', textAlign: 'center' }
            },
            {
                key: 'porcentaje_descuento',
                label: '% Descuento',
                class: 'text-center',
                thStyle: { minWidth: '7rem', textAlign: 'center' }
            },
            // { key: 'descuento', label: 'Descuento', class: 'text-center' },
            {
                key: 'total_iva',
                label: 'Iva',
                class: 'text-center',
                thStyle: { width: '20%', textAlign: 'center' }
            },
            {
                key: 'total_impoconsumo',
                label: 'Total impoconsumo',
                class: 'text-center',
                thStyle: { textAlign: 'center' }
            },
            {
                key: 'total',
                label: 'Total',
                class: 'text-center',
                thStyle: { width: '40%', textAlign: 'center' }
            },
            {
                key: 'acciones',
                label: 'Acciones',
                class: 'text-center',
                thStyle: { textAlign: 'center' }
            }
        ],
        total_retefuente: 0,
        total_reteiva: 0,
        proveedores: [],
        consecutivos: [],
        factura_venta_copy: defaultFacturaVenta(),
        factura_venta: defaultFacturaVenta(),
        isLoading: false,
        isSaved: true,
        read_only: false
        // formas_pago: []
    }),
    watch: {
        factura_venta: {
            handler(newValue) {
                const productos = newValue.productos
                
                if (productos.length > 0) {
                    this.getTotalRetefuente(productos)
                }
                
                const { params: { remision_id } } = this.$route
                
                if (!remision_id) {
                    this.isSaved = compareObjects(this.factura_venta_copy, newValue)
                } else {
                    this.isSaved = true
                }
            },
            deep: true
        }
    },
    mounted() {
        addListenerCommands(this)
        this.getParams()
        // this.getVendedores()
        this.getConceptosContables()
        this.getTerceros()
        this.getConsecutivos()
        this.addBlankProduct()
        setTimeout(() => {
            this.getLotesInventario()
            this.getBodegas()
            // this.getFormasPago()
        }, 500)
    },
    beforeDestroy() {
        removeListenerCommands(this)
    },
    beforeRouteLeave(to, from, next) {
        if (this.isSaved) {
            next()
        } else {
            this.$bvModal
                .msgBoxConfirm(
                    `
        Este formulario contiene información que no ha sido guardada,
        si continua con esta acción no se guardarán los cambios, desea continuar con esta acción?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        next()
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    validations: {
        factura_venta: {
            fecha: {
                required,
                compareDates: (d, a) => {
                    if (a.vencimiento) {
                        return compareDates(d, a.vencimiento)
                    }
                    return true
                },
                formatDate: (date) => {
                    if (date) {
                        const regExp =
                            /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/
                        const value = regExp.test(date)
                        return value
                    } else {
                        return true
                    }
                }
            },
            vencimiento: {
                required
            },
            proveedor_id: {
                required
            },
            // consecutivo_proveedor: {
            //     required
            // },
            consecutivo: {
                required
            },
            nota: {
                maxLength: maxLength(500)
            },
            productos: {
                // minLength: data => {
                //     return data.length > 0
                // },
                minLength: minLength(1),
                $each: {
                    descripcion: {
                        required,
                        maxLength: maxLength(60)
                    },
                    // precio_base_venta: {
                    //     required: (data, model) => {
                    //         if (
                    //             model.item_id !== null &&
                    //             model.item_id !== undefined &&
                    //             model.item_id !== ''
                    //         ) {
                    //             return data > 0
                    //         } else {
                    //             return true
                    //         }
                    //     }
                    // },
                    valor: {
                        // required: (data, model) => {
                        //     if (
                        //         model.item_id !== null &&
                        //         model.item_id !== undefined &&
                        //         model.item_id !== ''
                        //     ) {
                        //         return data > 0
                        //     } else {
                        //         return true
                        //     }
                        // }
                    },
                    cantidad: {
                        required: (data, model) => {
                            if (
                                model.item_id !== null &&
                                model.item_id !== undefined &&
                                model.item_id !== ''
                            ) {
                                return data > 0
                            } else {
                                return true
                            }
                        },
                        maxLength: maxLength(10)
                    },
                    porcentaje_descuento: {
                        maxLength: maxLength(6)
                    },
                    bodega_id: {},
                    lote_inventario_id: {},
                    tercero_id: {},
                    concepto_contable_id: {}
                }
            }
            // formas_pago: {
            //     $each: {
            //         forma_pago_id: {
            //             required: requiredIf(function () {
            //                 return !this.plazo_vencimiento != 0
            //             })
            //         },
            //         value: {
            //             minValue: function (model) {
            //                 if (this.plazo_vencimiento != 0) {
            //                     return true
            //                 }
            //                 return !model == 0
            //             }
            //         }
            //     }
            // }
        },
        plazo_vencimiento: {
            noNegativeNumbers: (data) => {
                const number = Number(data)
                return number > -1
            }
        }
    },
    methods: {
        onFocused,
        currencyFormat,
        goToNextField,
        filterTerceros,
        showLog(data) {
            console.log(data)
        },
        setNewItem(item) {
            this.productos.push(item)
            // console.log(item)
            this.insertProducto(item)
        },
        setNewProveedor(proveedor) {
            // console.log(establecimiento)
            this.proveedores.push(proveedor)
            this.assignPorveedor(proveedor.id)
        },
        setBodegaAndLote(item) {
            const { bodega_id, lote_inventario_id, concepto_contable_id, tercero_id } = item
            item.bodega_id = null
            item.lote_inventario_id = null
            item.concepto_contable_id = null
            item.tercero_id = null

            setTimeout(() => {
                // console.log(bodega_id, lote_inventario_id)
                item.bodega_id = bodega_id
                item.lote_inventario_id = lote_inventario_id
                item.concepto_contable_id = concepto_contable_id
                item.tercero_id = tercero_id
            }, 100)
        },
        getConceptosContables() {
            API.POST({
                url: 'administracion/concepto-digitacion/all',
                data: {
                    empresaId: this.getUltimaEmpresa.id
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.conceptos_contables = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                mensaje_exception ||
                                'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        setConsecutivo(consecutivo) {
            this.$v.factura_venta.consecutivo.$model = consecutivo
            this.factura_venta.numero = consecutivo.secuencia

            this.factura_venta_copy.consecutivo = consecutivo
            this.factura_venta_copy.numero = consecutivo.secuencia

            setTimeout(() => {
                const ELEMENT = document.getElementById('proveedor_id')
                ELEMENT.focus()
            }, 500)
        },
        checkPrefijo() {
            const isSelected =
                this.factura_venta.consecutivo?.prefijo !== '' &&
                this.factura_venta.consecutivo?.prefijo !== null
            if (!isSelected) {
                this.$bvModal.show('modal-prefijo')
            }
        },
        confirmRemoveItem(data) {
            const {
                item: { descripcion },
                index
            } = data

            this.$bvModal
                .msgBoxConfirm(
                    `Está seguro que desea eliminar el producto "${descripcion}" de la lista de productos de la factura de venta?`,
                    {
                        title: 'Advertencia!',
                        size: 'sm',
                        buttonSize: 'sm',
                        okVariant: 'danger',
                        okTitle: 'Continuar',
                        cancelTitle: 'Cancelar',
                        footerClass: 'p-2 d-flex justify-content-between',
                        hideHeaderClose: false,
                        centered: true,
                        headerBgVariant: 'danger',
                        headerClass: 'modal-header'
                    }
                )
                .then((value) => {
                    if (value) {
                        this.removeItemFromFacturaVenta(index)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        removeItemFromFacturaVenta(index) {
            this.factura_venta.productos.splice(index, 1)
        },
        calcTotalIva(data) {
            const result = Number(
                ((data.item.valor * data.item.cantidad -
                    (data.item.valor *
                        data.item.cantidad *
                        data.item.porcentaje_descuento) /
                        100) *
                    data.item.tarifa_iva.porcentaje) /
                    100
            )
            this.factura_venta.productos[data.index].total_iva = result
            this.factura_venta.productos[data.index].valor_iva = result
            return result
        },
        calcDescuento(data) {
            // console.log(data.item.porcentaje_descuento)
            const result = Number(
                data.item.cantidad *
                    data.item.valor *
                    (data.item.porcentaje_descuento / 100)
            )
            this.factura_venta.productos[data.index].descuento = result
            return result
        },
        calcTotalImpoconsumo(data) {
            // se verifica si el impoconsumo es porecntaje (0) y se hace el cálculo
            // sino es porcentaje se devuelve el valor
            // console.log(data.item.tipo_ipoconsumo == 0 ? 'porcentaje' : 'vslor')
            const {
                tipo_ipoconsumo,
                valor_ipoconsumo,
                porcentaje_descuento,
                valor,
                cantidad
            } = data.item
            let impoconsumo

            if (tipo_ipoconsumo == 0) {
                const descuento = (valor * porcentaje_descuento) / 100
                const valorNeto = valor - descuento
                impoconsumo = (valorNeto * valor_ipoconsumo) / 100
            } else if (tipo_ipoconsumo == 1) {
                impoconsumo = valor_ipoconsumo
            } else {
                impoconsumo = 0
            }

            const result = Number(impoconsumo * cantidad)
            this.factura_venta.productos[data.index].total_ipoconsumo = result
            return result
        },
        calcTotal(data) {
            const { cantidad, valor, total_iva, descuento } = data.item
            const subtotal = Number(cantidad * valor)
            const total = subtotal + total_iva - descuento
            this.factura_venta.productos[data.index].total = total
            this.factura_venta.productos[data.index].subtotal_neto = subtotal
            return total
            // const { cantidad, valor, descuento } = data.item
            // const result = Math.round(cantidad * valor)
            // const t = Math.round(cantidad * valor - descuento)
            // this.factura_venta.productos[data.index].total = result
            // this.factura_venta.productos[data.index].subtotal_neto = t
            // return result
        },
        calcValor(data) {
            const {
                index,
                item: {
                    precio_base_venta,
                    tarifa_iva,
                    tipo_ipoconsumo,
                    valor_ipoconsumo
                }
            } = data
            const porcentaje_iva = 1 + tarifa_iva.porcentaje / 100
            const valorNeto =
                tipo_ipoconsumo == 0 || tipo_ipoconsumo === 'NA'
                    ? precio_base_venta / porcentaje_iva
                    : (precio_base_venta - valor_ipoconsumo) / porcentaje_iva
            const result = Number(valorNeto)

            this.factura_venta.productos[index].valor = result

            this.calcTotalIva(data)
            this.calcDescuento(data)

            return result
        },
        filterProductos(text) {
            if (text && text.length > 3) {
                this.getProductos({ term: text })
            }
        },
        filterCliente(text) {
            if (text && text.length > 3) {
                this.getProveedores({ term: text })
            }
        },
        getNumeroFactura(id) {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'administracion/consecutivo/query',
                    data: {
                        p_datajson: {
                            id //id del consecutivo
                        },
                        p_opc: 'GET_NUMERO_SECUENCIA',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.factura_venta.numero = dato.numero
                            // this.consecutivos = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        getLotesInventario() {
            API.POST({
                url: 'administracion/inventario/lote-inventario/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.lotes_inventario = [...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getBodegas() {
            API.POST({
                url: 'administracion/inventario/bodega/query',
                data: {
                    p_datajson: {
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        this.bodegas = [...dato]
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getProveedores({ term }) {
            API.POST({
                url: 'general/tercero/query',
                data: {
                    p_datajson: {
                        term,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'GET_ALL_PROVEEDORES',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log(dato)
                        this.proveedores = dato
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getConsecutivos() {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'administracion/consecutivo/query',
                    data: {
                        p_datajson: {
                            clase_documento_id: 16,
                            empresa_id: this.getUltimaEmpresa.id
                        },
                        p_opc: 'GET_ALL_BY_CLASE_DOCUMENTO',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            // console.log(dato)
                            this.consecutivos = dato.map((c) => {
                                return c.prefijo === ''
                                    ? {
                                          ...c,
                                          prefijo: 'Sin prefijo'
                                      }
                                    : c
                            })
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        textFieldTercero(tercero) {
            return tercero.tipo_documento_id === '31'
                ? tercero.razon_social
                : `${tercero.nombre1} ${tercero.apellido1}`
        },
        getParams() {
            const { remision_id } = this.$route.params
            if (remision_id) {
                this.getProveedores({ term: '' })
                this.getRemision(remision_id)
            } else {
                this.checkPrefijo()
                // this.addFormaPago()
            }

            this.read_only = this.$route.meta.read_only
        },
        getRemision(id) {
            // console.log('acá 2');
            API.POST({
                url: 'administracion/remision-proveedor/query',
                data: {
                    p_datajson: {
                        id
                    },
                    p_opc: 'FIND_BY_ID',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // console.log('fv')
                        // console.log(dato)
                        const retrieveFacturaVenta = {
                            ...dato,
                            consecutivo: {
                                ...dato.consecutivo,
                                prefijo:
                                    dato.consecutivo.prefijo === ''
                                        ? 'Sin prefijo'
                                        : dato.consecutivo.prefijo
                            },
                            numero: dato.consecutivo.secuencia,
                            productos: dato.configuracion_impuestos.productos
                        }
                        this.factura_venta_copy = {
                            ...retrieveFacturaVenta
                        }
                        this.factura_venta = {
                            ...retrieveFacturaVenta
                        }
                        // const pc = daysLefts(
                        //     this.factura_venta.fecha,
                        //     this.factura_venta.vencimiento
                        // )
                        const pc = retrieveFacturaVenta.plazo
                        setTimeout(() => {
                            this.assignPorveedor(dato.proveedor_id, pc)
                            this.getTotalRetefuente(
                                dato.configuracion_impuestos.productos
                            )
                        }, 500)
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        getTerceros() {
            if (this.getUltimaEmpresa) {
                API.POST({
                    url: 'general/tercero/query',
                    data: {
                        p_datajson: {
                            empresa_id: this.getUltimaEmpresa.id
                        },
                        p_opc: 'GET_ALL',
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato,
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        if (status === 'ok') {
                            this.terceros = dato
                            this.tercerosCopy = dato
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
            }
        },
        addBlankProduct() {
            const producto = {
                ...defaultProducto(),
                cantidad: 0,
                referencia: '',
                descripcion: 'No seleccionado',
                precio_base_venta: 0,
                valor: 0,
                porcentaje_descuento: 0,
                descuento: 0,
                valor_iva: 0,
                total_iva: 0,
                tipo_ipoconsumo: 'NA',
                valor_ipoconsumo: 0,
                total_ipoconsumo: 0,
                retencion: null,
                bodega_id: 0,
                lote_inventario_id: 0,
                tarifa_iva: { porcentaje: 0 },
                item_id: null,
                total: 0
            }

            this.factura_venta.productos = [
                producto,
                ...this.factura_venta.productos
            ]

            this.factura_venta_copy.productos = [
                ...this.factura_venta.productos
            ]
        },
        insertProducto(product) {
            // console.log(product)
            const {
                descripcion,
                precio_base_venta,
                id: item_id,
                referencia,
                tipo_ipoconsumo,
                valor_ipoconsumo,
                iva: tarifa_iva,
                retencion,
                bodega_id,
                lote_inventario_id,
                concepto_contable_id
            } = product
            // const exist = this.factura_venta.productos.find(
            //     (producto) => producto.item_id === item_id
            // )
            // if (!exist) {
            const producto = {
                ...defaultProducto(),
                referencia,
                descripcion,
                precio_base_venta,
                valor: 0,
                porcentaje_descuento: 0,
                descuento: 0,
                valor_iva: 0,
                total_iva: 0,
                tipo_ipoconsumo,
                valor_ipoconsumo,
                total_ipoconsumo: 0,
                retencion,
                bodega_id,
                lote_inventario_id,
                tarifa_iva,
                concepto_contable_id,
                tercero_id: this.factura_venta.tercero_id,
                item_id,
                total: 0
            }

            // this.factura_venta.productos = [
            //     ...this.factura_venta.productos,
            //     producto
            // ]

            const [emptyItem, ...rest] = this.factura_venta.productos

            this.factura_venta.productos = [emptyItem, producto, ...rest]

            // setTimeout(() => {
            //     const blank_referencia =
            //         document.getElementById(`referencia-null`)
            //     const blank_producto = document.getElementById(`producto-null`)
            //     blank_referencia.value = ''
            //     blank_producto.value = ''
            // }, 50)
            this.goToNextField(`cantidad-producto-${item_id}`)
            // this.$v.productos.$model.push(producto)
            // }
            // else {
            //     this.$notify({
            //         group: 'general',
            //         title: 'Producto existente',
            //         text: `${descripcion} ya ha sido registrado`,
            //         ignoreDuplicates: true,
            //         duration: 5000,
            //         type: 'error'
            //     })
            // }
        },
        getProductos({ term }) {
            API.POST({
                // url: 'administracion/inventario/item/query',
                url: 'administracion/inventario/inventario-saldo/query',
                data: {
                    p_datajson: {
                        term,
                        bodega_id: this.getUltimaSede.bodega_id || 0,
                        lote_inventario_id:
                            this.getUltimaSede.lote_inventario_id || 0,
                        empresa_id: this.getUltimaEmpresa.id
                    },
                    p_opc: 'FILTER',
                    p_auditoriajson: this.auditoriajson
                }
            })
                .then((response) => {
                    const {
                        data: {
                            dato,
                            status: { status, mensaje, mensaje_exception }
                        }
                    } = response
                    if (status === 'ok') {
                        // this.productos = dato
                        this.productos = dato.content.map(
                            ({
                                lote_inventario_id,
                                bodega_id,
                                lote_inventario,
                                bodega,
                                item
                            }) => {
                                return {
                                    lote_inventario_id,
                                    lote_inventario,
                                    bodega_id,
                                    bodega,
                                    ...item
                                }
                            }
                        )
                    } else {
                        this.$bvModal
                            .msgBoxOk(
                                mensaje ||
                                    mensaje_exception ||
                                    'Ha ocurrido un error!',
                                {
                                    title: 'Error!',
                                    size: 'sm',
                                    buttonSize: 'sm',
                                    okVariant: 'error',
                                    okTitle: 'Aceptar',
                                    cancelTitle: 'Cancelar',
                                    footerClass: 'p-2',
                                    hideHeaderClose: false,
                                    centered: true,
                                    headerBgVariant: 'error',
                                    headerClass: 'modal-header'
                                }
                            )
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    }
                })
                .catch((error) => {
                    const { message } = error
                    this.$bvModal
                        .msgBoxOk(message || 'Ha ocurrido un error!', {
                            title: 'Error!',
                            size: 'sm',
                            buttonSize: 'sm',
                            okVariant: 'error',
                            okTitle: 'Aceptar',
                            cancelTitle: 'Cancelar',
                            footerClass: 'p-2',
                            hideHeaderClose: false,
                            centered: true,
                            headerBgVariant: 'error',
                            headerClass: 'modal-header'
                        })
                        .then(() => {
                            // console.log(value);
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                })
        },
        assignPorveedor(proveedor_id, pc) {
            // console.log(proveedor_id)
            if (proveedor_id) {
                const proveedor = this.proveedores.find(
                    (c) => c.id === proveedor_id
                )
                // console.log(cliente)
                // const { id, plazo_credito, tercero_id, tercero } = cliente

                const {
                    declarante,
                    agente_retencion_fuente,
                    regimen_simple_tributacion,
                    gran_contribuyente,
                    plazo,
                    id: tercero_id
                } = proveedor

                const {
                    agente_retencion_fuente: arfEmpresa,
                    autoretenedor_fuente,
                    agente_retencion_iva,
                    gran_contribuyente: gcEmpresa
                } = this.getUltimaEmpresa

                this.retencionBaseData = Object.assign(this.retencionBaseData, {
                    tercero: {
                        declarante,
                        agente_retencion_fuente,
                        regimen_simple_tributacion,
                        gran_contribuyente
                    },
                    empresa: {
                        agente_retencion_fuente: arfEmpresa,
                        autoretenedor_fuente,
                        agente_retencion_iva,
                        gran_contribuyente: gcEmpresa
                    }
                })
                // this.factura_venta.vendedor_id =
                //     this.vendedores.length > 0 ? this.vendedores[0].id : null
                this.factura_venta.tercero_id = proveedor.id
                this.$v.factura_venta.proveedor_id.$model = proveedor.id

                const products = this.factura_venta.productos.map(p => {
                    let newProduct = null
                    if (p.tercero_id) {
                        newProduct = { ...p }
                    } else {
                        newProduct = { ...p, tercero_id }
                    }

                    return newProduct
                })

                this.factura_venta.productos = products

                if (pc !== undefined && pc !== null && pc !== '') {
                    this.assignVencimiento(pc)
                } else {
                    this.assignVencimiento(plazo)
                }
            } else {
                this.factura_venta.proveedor_id = null
                this.factura_venta.tercero_id = null
                // this.factura_venta.vendedor_id = null
                this.assignVencimiento(0)
            }
        },
        assignVencimiento(value) {
            // console.log(value)
            const plazo = value ? Number(value) : 0

            this.$v.plazo_vencimiento.$model = plazo
            this.$v.factura_venta.vencimiento.$model = dateNow(plazo)

            // if (!this.$route.params.factura_id) {
            //     if (plazo === 0) {
            //         this.factura_venta.formas_pago = []
            //         this.addFormaPago()
            //     } else {
            //         this.factura_venta.formas_pago = []
            //     }
            //     this.$v.factura_venta.formas_pago.$reset()
            // }
        },
        getTotalRetefuente(productos) {
            const obj = calcRetefuente(productos, this.retencionBaseData)

            const {
                total_retefuente,
                total_reteiva,
                retencion_detail,
                reteiva_detail
            } = obj

            // console.log(reteiva_detail)

            const reteiva = Object.keys(reteiva_detail.gruposReteIva).map(
                (key) => {
                    // const valorGrupo = (reteiva_detail.gruposReteIva[key] * Number(key)) / 100
                    return {
                        group: key,
                        value: currencyFormat(
                            Number(reteiva_detail.gruposReteIva[key])
                        )
                    }
                }
            )

            const retencion = Object.keys(retencion_detail.gruposRetencion).map(
                (key) => {
                    const valorGrupo =
                        (retencion_detail.gruposRetencion[key] * Number(key)) /
                        100
                    return {
                        group: key,
                        base_retencion: currencyFormat(
                            retencion_detail.retenciones[key].base_retencion
                        ),
                        value: currencyFormat(Number(valorGrupo))
                    }
                }
            )

            // console.log(retencion, reteiva)

            this.detalle_factura = {
                ...this.detalle_factura,
                retencion,
                reteiva
            }

            this.total_retefuente = total_retefuente
            this.total_reteiva = total_reteiva
        },
        save() {
            this.factura_venta.productos.splice(0, 1)
            this.productsLengthError = this.factura_venta.productos.length < 1
            // let comparisonFormasPagoGranTotal = true

            // if (this.plazo_vencimiento == 0) {
            //     comparisonFormasPagoGranTotal =
            //         this.formasPagoValue >= this.granTotal
            // }

            const valid =
                !this.$v.factura_venta.$invalid &&
                !this.$v.plazo_vencimiento.$invalid &&
                !this.productsLengthError
            // console.log(this.$v)
            if (valid) {
                // if (!comparisonFormasPagoGranTotal) {
                //     this.$notify({
                //         group: 'general',
                //         title: 'Valor del pedido',
                //         text: 'El valor total de las formas de pago no es mayor o igual al valor total de la compra',
                //         ignoreDuplicates: true,
                //         duration: 5000,
                //         type: 'error'
                //     })
                // } else {
                const {
                    total,
                    descuento: total_descuento,
                    total_ipoconsumo
                } = this.totalesFacturas

                // console.log(this.factura_venta.consecutivo)
                this.factura_venta.productos = this.factura_venta.productos.map(
                    (p) => ({
                        ...p,
                        cliente_id: this.factura_venta.cliente_id
                    })
                )

                const consecutivo = JSON.parse(
                    JSON.stringify(this.factura_venta.consecutivo)
                )

                const factura_venta_extra_data = {
                    consecutivo: {
                        ...consecutivo,
                        prefijo:
                            consecutivo.prefijo === 'Sin prefijo'
                                ? ''
                                : consecutivo.prefijo
                    },
                    usuario_id: this.getUserData.id,
                    empresa_id: this.getUltimaEmpresa.id,
                    sede_id: this.getUltimaSede.id,
                    estado_documento_id: 1,
                    total,
                    gran_total: this.granTotal,
                    subtotal_neto: total - total_descuento,
                    subtotal: total,
                    total_descuento,
                    total_ipoconsumo,
                    total_retefuente: this.total_retefuente,
                    total_reteiva: this.total_reteiva,
                    cambio: this.cambio,
                    plazo: this.plazo_vencimiento,

                    configuracion_impuestos: {
                        retefuente: this.total_retefuente,
                        reteiva: this.total_reteiva,
                        productos: this.factura_venta.productos
                    }
                }

                const factura_venta = Object.assign(
                    this.factura_venta,
                    factura_venta_extra_data
                )

                // console.log(factura_venta)
                this.isLoading = true
                const p_opc = this.factura_venta.id ? 'UPDATE' : 'INSERT'
                API.POST({
                    url: 'administracion/remision-proveedor/crud',
                    data: {
                        p_datajson: {
                            ...factura_venta
                        },
                        p_opc,
                        p_auditoriajson: this.auditoriajson
                    }
                })
                    .then((response) => {
                        const {
                            data: {
                                dato: { document: documentBase64 },
                                status: { status, mensaje, mensaje_exception }
                            }
                        } = response
                        this.isLoading = false
                        if (status === 'ok') {
                            this.isSaved = true
                            if (this.factura_venta.consecutivo.auto_print) {
                                const base64 = documentBase64.replace(
                                    'data:application/pdf;base64,',
                                    ''
                                )
                                print({
                                    printable: base64,
                                    type: 'pdf',
                                    base64: true
                                })
                            }
                            // this.factura_venta = defaultFacturaVenta()
                            if (p_opc === 'INSERT') {
                                // this.$router.go()
                                // const consecutivo = JSON.parse(
                                //     JSON.stringify(
                                //         this.factura_venta.consecutivo
                                //     )
                                // )
                                // console.log(consecutivo)
                                consecutivo.secuencia += 1
                                this.factura_venta = defaultFacturaVenta()
                                this.factura_venta.cliente_id = null
                                this.total_retefuente = 0
                                this.total_reteiva = 0
                                this.detalle_factura = {}
                                this.$v.factura_venta.$reset()
                                this.assignPorveedor(null)
                                this.setConsecutivo(consecutivo)
                                this.addBlankProduct()
                                // const productos_input =
                                //     document.getElementById('productos')
                                // productos_input.value = ''

                                this.$notify({
                                    group: 'general',
                                    title: 'Remisión Creada!',
                                    text: `La remisión se ha creado correctamente`,
                                    ignoreDuplicates: true,
                                    duration: 5000,
                                    type: 'success'
                                })
                            } else {
                                this.back()
                            }
                        } else {
                            this.$bvModal
                                .msgBoxOk(
                                    mensaje ||
                                        mensaje_exception ||
                                        'Ha ocurrido un error!',
                                    {
                                        title: 'Error!',
                                        size: 'sm',
                                        buttonSize: 'sm',
                                        okVariant: 'error',
                                        okTitle: 'Aceptar',
                                        cancelTitle: 'Cancelar',
                                        footerClass: 'p-2',
                                        hideHeaderClose: false,
                                        centered: true,
                                        headerBgVariant: 'error',
                                        headerClass: 'modal-header'
                                    }
                                )
                                .then(() => {
                                    // console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err)
                                })
                        }
                    })
                    .catch((error) => {
                        this.isLoading = false
                        const { message } = error
                        this.$bvModal
                            .msgBoxOk(message || 'Ha ocurrido un error!', {
                                title: 'Error!',
                                size: 'sm',
                                buttonSize: 'sm',
                                okVariant: 'error',
                                okTitle: 'Aceptar',
                                cancelTitle: 'Cancelar',
                                footerClass: 'p-2',
                                hideHeaderClose: false,
                                centered: true,
                                headerBgVariant: 'error',
                                headerClass: 'modal-header',
                                noStacking: true
                            })
                            .then(() => {
                                // console.log(value);
                            })
                            .catch((err) => {
                                console.log(err)
                            })
                    })
                // }
            } else {
                this.addBlankProduct()
                this.$v.factura_venta.$touch()
            }
        },
        getLabelCliente(item) {
            const {
                tipo_documento_id,
                nombre1,
                nombre2,
                apellido1,
                apellido2,
                razon_social,
                n_documento
            } = item
            let label = ''

            if (tipo_documento_id == 13) {
                label = `${nombre1}${nombre2 && ` ${nombre2}`} ${apellido1}${
                    apellido2 && ` ${apellido2}`
                }`
            } else {
                label = `${razon_social}`
            }

            label = `${label} - ${n_documento}`

            return label
        },
        getLabelItem(item) {
            const {
                descripcion,
                referencia,
                codigo_barra,
                contenido: existencia,
                precio_base_venta
            } = item
            let label = ''

            label = `${descripcion} - ${referencia} - ${codigo_barra} - ${existencia} - ${currencyFormat(
                precio_base_venta
            )}`

            return label
        },
        back() {
            this.$router.back()
        },
        validateState(key) {
            const { $dirty, $error } = this.$v.factura_venta[key]
            return $dirty ? !$error : null
        },
        validateStateSingle(key) {
            const { $dirty, $error } = this.$v[key]
            return $dirty ? !$error : null
        },
        validateStateProducts(index, key) {
            const { $dirty, $error } =
                this.$v.factura_venta.productos.$each[index][key]
            return $dirty ? !$error : null
        },
        openItemsTable() {
            this.$refs.itemsButton.click()
        }
        // validateStateFormasPago(index, key) {
        //     const { $dirty, $error } =
        //         this.$v.factura_venta.formas_pago.$each[index][key]
        //     return $dirty ? !$error : null
        // }
    },
    computed: {
        granTotal() {
            const {
                subtotal_neto: subtotal,
                total_ipoconsumo: impoconsumo,
                total_iva: iva,
                descuento
            } = this.totalesFacturas

            const total =
                subtotal +
                iva +
                impoconsumo -
                (descuento + this.total_retefuente + this.total_reteiva)

            return total
        },
        totalesFacturas() {
            const fields = [
                'total',
                'descuento',
                'total_ipoconsumo',
                'total_iva',
                'subtotal_neto'
            ]
            const productos = this.factura_venta.productos
            const totales = {}

            fields.forEach((field) => {
                const arrayTotal = productos.map((producto) => producto[field])

                const total = arrayTotal.reduce((a, b) => Math.round(a + b), 0)

                totales[field] = total
            })

            return totales
        },
        // formasPagoValue() {
        //     return this.factura_venta.formas_pago
        //         .map((f) => f.value)
        //         .reduce((a, b) => Number(a) + Number(b), 0)
        // },
        cambio() {
            if (this.formasPagoValue > this.granTotal) {
                const value = this.formasPagoValue - this.granTotal
                return value
            } else {
                return 0
            }
        },
        keymap() {
            if (
                navigator.platform.toLowerCase().includes('mac') ||
                navigator.platform.toLowerCase().includes('macintosh')
            ) {
                return {
                    'command+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back,
                    'command+b': this.read_only ? () => {} : this.openItemsTable
                }
            } else if (
                navigator.platform.toLowerCase().includes('win') ||
                navigator.platform.toLowerCase().includes('windows')
            ) {
                return {
                    'ctrl+shift+s': this.read_only ? () => {} : this.save,
                    esc: this.back,
                    'ctrl+b': this.read_only ? () => {} : this.openItemsTable
                }
            } else {
                return {}
            }
        },
        permisos() {
            return this.getPermisos.permisos_authorities
        },
        auditoriajson() {
            const json = { ...this.getAuditoriaJson, modulo_id: 49 }
            return json
        },
        ...mapGetters('usuario', ['getUserData', 'getAuditoriaJson', 'getPermisos']),
        ...mapGetters('empresas', ['getUltimaEmpresa']),
        ...mapGetters('sedes', ['getUltimaSede'])
    }
}
</script>

<style></style>
